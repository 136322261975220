@font-face {
    font-family: rubik;
    src: url('./static/fonts/Rubik-VariableFont_wght.ttf');
}

@font-face {
    font-family: komika;
    src: url('./static/fonts/KOMIKAX_.ttf');
}


:root {
    --darkpuprle: #5d3c85;
    --purple: #9B5DE5;
    --milkwhite: #fffaed;
    --pink: #F15BB5;
    --blue: #00BBF9;
    --green: #00F5D4;
    --yellow: #FEE440;

    --headerfont: komika;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: white; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom Styles */


/* Common */

body {
    font-family: rubik !important;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.8em;
    font-weight: 600;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
}

.note {
    border: 1px solid #fffaed;
    color: white;
    border-radius: 8px;
    padding: 1em;
    text-align: left;
    font-size: 0.8em;
    background: rgba(255,255,255,0.2) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(6px) !important;
}

.note-title {
    font-weight: 600;
}

.base-label {
    font-size: 0.9em;
    text-transform: capitalize;
    font-weight: 600;
}

.addons-label {
    text-transform: capitalize;
    font-size: 0.8em;
}

.loading-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#header-fold {
    background-color: #fffaed;
    overflow-x: hidden;
    color: #5d3c85;
}

.spacer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    aspect-ratio: 1920/240;
}

.topbar-spacer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    aspect-ratio: 1920/50;
}

.wave {
    background-image: url('/src/static/svg/wave-haikei\ \(1\).svg');
}

.wave2 {
    background-image: url('/src/static/svg/wave-haikei\ \(2\).svg');
}

.wave3 {
    background-image: url('/src/static//svg/wave-haikei\ \(3\).svg');
}

.blob {
    position: absolute;
    fill: #f8f0d5;
    width: 50vmin;
    z-index: 2;
    animation: move 10s ease-in-out infinite;
}
  
@keyframes move {
    0%   { transform: scale(1)   translate(10px, -20px); }
    38%  { transform: scale(0.8, 1) translate(4vw, 2vh) rotate(160deg); }
    40%  { transform: scale(0.8, 1) translate(4vw, 2vh) rotate(160deg); }
    78%  { transform: scale(1.2) translate(3vw, 2vh) rotate(-20deg); }
    80%  { transform: scale(1.2) translate(3vw, 2vh) rotate(-20deg); }
    100% { transform: scale(1)   translate(10px, -20px); }
}

.upside-down {
    transform: rotate(180deg);
}

.full-width {
    width: 100%;
}

.width-90 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.bottom-bar-spacer {
    height: 0;
}

/* Nav */

#home-topbar {
    font-size: 0.8em;
    padding: 1em;
    background-color: var(--milkwhite);
    color: var(--darkpuprle) !important;
}

.topbar {
    margin-left: auto;
    margin-right: auto;
    background-color: var(--milkwhite) !important;
}

.topbar > div {
    width: 94%;
}

.navbar-brand, .nav-link {
    color: var(--darkpuprle) !important;
}

#topbar > .navbar-brand, #topbar > .nav-link {
    color: white !important;
}

#navbarNavDropdown {
    justify-content: flex-end;
}

.navbar-toggler {
    background-color: transparent;
    border: none !important;
}

.navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg);
}

.nav-link:hover {
    text-decoration: underline;
}

/* Home */

footer {
    padding: 1em;
    min-height: 30vh !important;
    background-color: var(--purple);
    color: var(--milkwhite);
}

.logo {
    font-family: rubik !important;
    font-weight: 600;
}

h3.logo {
    margin-bottom: 0 !important;
}

#byob-button {
    background-color: var(--purple);
    color: white;
    margin-top: 1em;
}

#learn-more {
    margin-top: 1em;
    text-align: left;
    max-width: 94%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#learn-more > h1 {
    font-weight: 600;
    color: var(--darkpuprle);
    font-family: var(--headerfont);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--purple);
}

.learn-more-card {
    margin-bottom: 1em;
}

.learn-more-card-container {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
}

.learn-more-card-text {
    text-align: left;
    color: var(--darkpuprle);
}

.learn-more-card-text > div {
    font-size: 0.7em;
}

.learn-more-card-text > h4 {
    font-family: var(--headerfont);
}

.learn-more-card-graphic {
    border: 3px solid var(--milkwhite);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: var(--darkpuprle);
}

.learn-more-card-container > .col-md-6 {
    margin-bottom: 1em;
}

.learn-more-card-container > .col-md-6 > .card {
    background: rgba(255,255,255,0.4) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(6px) !important;
    padding: 1em;
    border: 2px solid var(--milkwhite);
    color: var(--darkpurple);
    height: 100%;
}

.learn-more-card-container > .col-md-6 > .card > .row {
    height: 100%;
}

#tailor-made {
    background-color: var(--pink);
}

#assorted {
    background-color: var(--yellow);
}

#natural {
    background-color: var(--green);
}

#nourishing {
    background-color: var(--blue);
}

.home-cta-container {
    margin-bottom: 2em;
    z-index: 3;
    text-align: left;
    padding: 1em;
}

.home-cta-container > h1 {
    font-family: var(--headerfont);
    text-transform: uppercase;
}

.footer-content {
    padding: 2em;
    text-align: left;
}

.footer-navigation > a {
    font-size: 0.8em;
    color: var(--milkwhite);
    opacity: 0.7;
    text-decoration: none;
}

.footer-navigation > a:hover {
    opacity: 1;
}

.footer-contact > div {
    opacity: 0.8;
}

.footer-contact > a {
    font-size: 1.2em;
    margin-right: 0.5em;
    opacity: 0.8;
}

.footer-logo {
    text-align: center;
}

.contact-email-container {
    margin-top: 1em;
    font-size: 0.8em;
}

/* BYOB */

.progress {
  height: 10px !important;
  border: 1px solid var(--milkwhite);
}

.width-90 {
  width: 90%;
}

.main-container {
  margin-left: auto;
  margin-right: auto;
}

#byob-step-content {
  margin-top: 1em;
}

.byob-step {
  text-align: left;
  font-weight: 600;
  color: var(--milkwhite);
}

.step-subtitle {
  font-size: 0.8em;
  font-weight: 400 !important;
}

.step-button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--milkwhite) !important;
  font-weight: 600;
  background-color: var(--purple) !important;
}

.step-button:hover, .step-button:active {
    background-color: var(--milkwhite) !important;
    color: var(--purple) !important;
}

.step-button:disabled {
  opacity: 0.5;
}

.base-option-card {
  min-width: 160px !important;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
  background: rgba(255,255,255,0.6) !important;
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
  color: var(--purple);
  border: 3px solid var(--milkwhite) !important;
}

.option-selected {
  border: 5px solid var(--purple) !important;
  border-radius: 0.75em !important;
}

.invalid-field {
  border: 1px solid red !important;
}

.invalid-feedback {
    display: block !important;
    text-align: left !important;
    font-size: smaller;
}

/* Cart */

#empty-cart {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: gray !important;
  min-height: 70vh;
}

.empty-cart-content > h1 {
    font-family: var(--headerfont);
    color: var(--darkpuprle);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--purple);
}

#cart-details {
    text-align: left;
}

.cart-item-quantity {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding-left: 5px;
    padding-right: 5px;
}

#cart-items {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 1em;
    border: 3px solid var(--milkwhite);
    padding: 1em;
    border-radius: 10px;
    background: rgba(255,255,255,0.6) !important;
    backdrop-filter: blur(6px) !important;
   -webkit-backdrop-filter: blur(6px) !important;
}

#cart-items > h5 {
    font-weight: 600;
    color: var(--darkpuprle);
    margin-bottom: 1em;
}

.cart-item-weight {
    font-size: 0.6em;
    font-weight: 600;
    background-color: var(--darkpuprle);
    color: white;
    padding: 2px;
    max-width: 32px !important;
    text-align: center;
    border-radius: 4px;
}

.cart-item-product-price {
    font-size: 0.6em;
    font-weight: 600;
    background-color: var(--green);
    color: black;
    padding: 2px;
    max-width: 32px !important;
    text-align: center;
    border-radius: 4px;
    margin-left: 3px;
}

.product-price {
    font-size: 0.8em;
    font-weight: 600;
    background-color: var(--green);
    color: black;
    padding: 2px;
    max-width: 48px !important;
    text-align: center;
    border-radius: 4px;
    margin-left: 3px;
}

.cart-item-product-link {
    font-size: 0.8em;
    margin-left: 4px;
    color: var(--blue) !important;
}

.cart-item-price {
    font-size: 0.8em;
    margin-top: 1em;
}

.cart-item-remove {
    font-size: 0.6em;
    color:var(--pink);
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.cart-item-remove:hover {
    text-decoration: underline;
    opacity: 0.6;
}

.cart-item {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    color: var(--darkpuprle);
}

.cart-item-details {
    width: 50%;
    word-wrap: break-word;
}

#add-more-items {
    border: none;
    background-color: var(--purple);
    color: white;
    border-radius: 6px;
    padding: 0.4em;
}

#cart-checkout {
    background-color: var(--darkpuprle);
    border: none;
}

.small-bold {
    font-weight: 600;
    font-size: x-small;
}

/* Checkout */

#payment-summary {
    padding: 1em;
}

#payment-button {
    min-width: 100px;
    background-color: var(--darkpuprle);
    color: var(--milkwhite);
}

table {
    font-size: 0.8em !important;
}

#checkout-tabs {
    cursor: pointer;
}

#checkout-tabs > .nav-item > .nav-link.active {
    background: rgba(255,255,255,0.2) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(6px) !important;
    font-weight: 600;
    border-color: #664e3d #664e3d rgba(255,255,255,0.2); 
}

.option-card-price-line {
    font-size: 0.8em;
    color: var(--darkpuprle);
}

.payment-details-parent-container {
    background: rgba(255,255,255,0.4) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(6px) !important;
    border: 1px solid var(--milkwhite);
    color: var(--darkpuprle);
    border-radius: 8px;
    font-size: 0.8em;
    padding: 1em;
    font-weight: 500;
}

.payment-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#step3-button-container {
    z-index: 3;
}

#step3-button-container > button {
    background-color: var(--purple);
    border: none;
}

/* Order */

.order-main-container {
    text-align: left;
}

.order-main-container > h1 {
    font-weight: 600;
    margin-bottom: 1em;
    margin-top: 1em;
}

.status-badge {
    font-size: small;
    display: block;
    color: whitesmoke;
    width: 60px;
    padding: 0.1em;
    text-align: center;
    border-radius: 6px;
}

.status-pending {
    background-color: goldenrod;
}

.status-preparing {
    background-color: purple;
}

.status-shipped {
    background-color: navy;
}

.status-delivered {
    background-color: #20c997;
}

.status-complete {
    background-color: #20c997;
}

.status-failed, .status-cancelled {
    background-color:orangered;
}

.order-details-subheading {
    font-size: small;
    font-weight: 600;
}

.order-header, .order-date {
    color: var(--milkwhite);
}

.order-details-content-container {
    background: rgba(255,255,255,0.4) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(6px) !important;
    padding: 1em;
    border: 2px solid var(--milkwhite);
    border-radius: 8px;
}

.order-details-content-container > div {
    color: var(--darkpuprle);
}

/* Product */

.product-details-container {
    text-align: left;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.product-details-header {
    text-align: left;
    font-weight: 600 !important;
    color:var(--milkwhite);
    text-transform: capitalize;
    
}

.product-mini-image {
    margin-left: 6px;
}

.nutrition-tag {
    background-color: var(--darkpuprle);
    color: white;
    font-size: 0.7em;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 3em;
    margin-right: 2px;
    margin-bottom: 2px;
    display: inline-block;
    border: 1px solid var(--purple);
}

#animate-logo {
    margin-bottom: 1em;
    /* animation: rotation 3s infinite linear; */
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}


/* Test Styles */
.redbox {
    border: 1px solid red;
}

@media (max-width: 800px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */


    /* Common */

    .button-container {
        position: fixed;
        bottom: 0;
        margin-bottom: 0;
        background-color: var(--purple);
        padding: 1em;
        width: 100%;
    }

    .bottom-bar-spacer {
        height: 8vh;
    }

    /* BYOB */

    #step1button {
        width: 100%;
    }

    /* Cart */

    #cart-checkout {
        background-color: var(--purple);
    }

    #cart-items {
        margin-bottom: 3em;
    }
    
}
