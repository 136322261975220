body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#pattern {
    position: fixed;
    height: 100vh;
    width: 100vw;
    /* background-color: #e5e5f7;
    opacity: 0.1;
    background-image: radial-gradient(circle at center center, #664e3d, #e5e5f7), repeating-radial-gradient(circle at center center, #664e3d, #664e3d, 25px, transparent 50px, transparent 25px);
    background-blend-mode: multiply; */
    z-index: -100;
    background-image: url('/src/static/images/almond-butter-closeup.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    filter: contrast(0.5);
    /* filter: blur(5px); */
}
